
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SkillMatrices } from '../models';
import { skillsService } from '../services';
import SkillMatricesList from './SkillMatricesList.vue';

@Component({ components: { SkillMatricesList } })
export default class SkillMatricesPanel extends Vue {
  draftMatrices: SkillMatrices = [];
  activeMatrices: SkillMatrices = [];
  closedMatrices: SkillMatrices = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  created() {
    this.$bind(
      'draftMatrices',
      skillsService.getSkillMatricesByStatus(this.userId, 'Draft').orderBy('createdOn', 'desc')
    );
    this.$bind(
      'activeMatrices',
      skillsService.getSkillMatricesByStatus(this.userId, 'Active').orderBy('createdOn', 'desc')
    );
    this.$bind(
      'closedMatrices',
      skillsService.getSkillMatricesByStatus(this.userId, 'Closed').orderBy('createdOn', 'desc')
    );
  }
}
