
import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { SkillMatrix, skillMatrixStatuses, skillMatrixTypes } from '../models';
import { skillsService, skillCategories } from '../services';
import ColorPicker from '@/components/ColorPicker.vue';
import ImportSkillsDialog from './dialogs/ImportSkillsDialog.vue';
import SkillMatrixSettings from './SkillMatrixSettings.vue';

@Component({ components: { ColorPicker, ImportSkillsDialog, SkillMatrixSettings } })
export default class SkillMatrixBuilder extends Vue {
  @PropSync('value', { type: Object as () => SkillMatrix, required: true }) matrix!: SkillMatrix;

  skillsService = skillsService;
  skillMatrixTypes = skillMatrixTypes;
  skillCategories = skillCategories;
  skillMatrixStatuses = skillMatrixStatuses;

  config = {};
  valid = false;
  showImportDialog = false;

  get skills() {
    return Object.values(this.matrix.skills).sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  }
  get skillMatrixExists(): boolean {
    return this.matrix.createdOn !== undefined;
  }

  @Watch('session.title')
  onTitleChange(title) {
    document.title = title;
  }

  resetRequirements() {
    this.skills.forEach((skill) => {
      skill.requirements = new Array(this.matrix.config.proficiencyScale.levels.length).fill(0);
    });
  }

  saveSkillMatrix() {
    this.$emit('save', this.matrix);
  }

  deleteSkillMatrix() {
    // this.$emit('delete', this.matrix);
    this.$bus.$emit('skillmatrix-delete', this.matrix);
  }

  setSkillMatrixStatus(status: string) {
    if (status === 'Active') {
      this.matrix.status = skillMatrixStatuses.ACTIVE;
    } else if (status === 'Closed') {
      this.matrix.status = skillMatrixStatuses.CLOSED;
    }
    this.saveSkillMatrix();
  }
}
