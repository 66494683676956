
import { Vue, Component } from 'vue-property-decorator';
import { SkillMatrix, User } from '../models';
import { skillsService } from '../services';
import SkillMatricesPanel from '../components/SkillMatricesPanel.vue';
import ImportSkillMatrixDialog from '../components/dialogs/ImportSkillMatrixDialog.vue';

@Component({ components: { SkillMatricesPanel, ImportSkillMatrixDialog } })
export default class SkillMatrixDashboard extends Vue {
  showImportDialog = false;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get viewModeIcon(): string {
    return this.$store.getters['user/viewModeIcon'];
  }

  created() {
    // Event listeners
    this.$bus.$off('skillmatrix-clone').$on('skillmatrix-clone', this.onSkillMatrixClone);
    this.$bus.$off('skillmatrix-export').$on('skillmatrix-export', this.onSkillMatrixExport);
    this.$bus.$off('skillmatrix-delete').$on('skillmatrix-delete', this.onSkillMatrixDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Skills', '/skills');
    document.title = 'AgileMate Skills';
    this.$analytics.logEvent('skills-dashboard');
    // this.$bind('config', skillsService.configRef);
  }

  // Event handlers
  async onSkillMatrixClone(matrix: SkillMatrix) {
    try {
      await skillsService.cloneSkillMatrix(matrix, this.user);
      this.$bus.$emit('snackbar-notify', 'Skill matrix successfully cloned', 'success');
      this.$analytics.logEvent('skills-clone', { matrix_id: matrix.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSkillMatrixExport(matrix: SkillMatrix) {
    try {
      skillsService.exportSkillMatrix(matrix);
      this.$bus.$emit('snackbar-notify', 'Skill matrix successfully exported', 'success');
      this.$analytics.logEvent('skills-export', { matrix_id: matrix.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSkillMatrixDelete(matrix: SkillMatrix) {
    try {
      await skillsService.deleteSkillMatrix(matrix);
      this.$bus.$emit('snackbar-notify', 'Skill matrix successfully deleted', 'success');
      this.$analytics.logEvent('skills-delete', { matrix_id: matrix.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
