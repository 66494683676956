
import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { hexColor } from '@/utils';
import apexchart from 'vue-apexcharts';
import { SkillMatrix, TeamMember } from '../../models';
import { skillsService } from '../../services';

@Component({ components: { apexchart } })
export default class PersonSkillLevelChartDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  @Prop({ type: Object as () => SkillMatrix, required: true }) readonly matrix!: SkillMatrix;
  @Prop({ type: Object as () => TeamMember, required: true }) readonly person!: TeamMember;

  get levels() {
    return this.matrix.config.proficiencyScale.levels;
  }

  get skills() {
    return Object.values(this.matrix.skills);
  }

  get skillNames() {
    return this.skills.map((skill) => skill.name);
  }

  get personName() {
    return this.person.displayName || this.person.username;
  }

  get personSkillsOptions() {
    return {
      chart: {
        type: 'radar',
      },
      title: {
        text: `Person Skills Distribution - ${this.personName}`,
      },
      theme: {
        mode: this.$vuetify.theme.dark ? 'dark' : 'light',
      },
      dataLabels: {
        enabled: false,
        background: {
          enabled: true,
          borderRadius: 2,
        },
      },
      fill: {
        opacity: 0.5,
      },
      plotOptions: {
        radar: {
          polygons: {
            fill: {
              colors: this.levels.map((level) => hexColor(level.color)).reverse(),
            },
          },
        },
      },
      xaxis: {
        categories: this.skillNames,
      },
      yaxis: {
        min: 0,
        max: this.levels.length - 1,
        stepSize: 1,
        labels: {
          formatter: (value: number) => this.levels[value]?.name,
        },
      },
    };
  }

  get personSkills() {
    return [
      {
        name: 'Current level',
        data: skillsService.getPersonCurrentSkillLevels(this.matrix, this.person),
      },
      {
        name: 'Target level',
        data: skillsService.getPersonTargetSkillLevels(this.matrix, this.person),
      },
    ];
  }
}
