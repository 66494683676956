
import { Vue, Component, ModelSync } from 'vue-property-decorator';
import { SkillMatrix } from '../../models';
import { skillsService } from '../../services';

@Component
export default class ImportSkillMatrixDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  importing = false;
  importFiles: File[] = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  async onMatrixImport() {
    try {
      for (let file of this.importFiles) {
        this.importing = true;
        const matrix: SkillMatrix = await skillsService.importSkillMatrix(file, this.userId);
        await skillsService.createSkillMatrix(matrix);
        this.$bus.$emit('snackbar-notify', 'Skill matrix successfully imported', 'success');
        this.show = false;
        this.importFiles = [];
        this.$analytics.logEvent('skills-import', { matrix_id: matrix.id, user_id: this.userId });
      }
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
    this.importing = false;
  }
}
