
import { Vue, Component, ModelSync, Prop } from 'vue-property-decorator';
import { SkillMatrix, TeamMember } from '../../models';
import { skillsService } from '../../services';

@Component({ components: {} })
export default class UpdatePersonDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  @Prop({ required: true }) readonly matrix!: SkillMatrix;
  @Prop({ required: true }) readonly person!: TeamMember;

  skillsService = skillsService;
  isFormValid = false;

  savePerson(matrix: SkillMatrix, person: TeamMember) {
    this.show = false;
    skillsService.updatePerson(matrix.id, person);
  }

  deletePerson(matrix: SkillMatrix, person: TeamMember) {
    this.show = false;
    skillsService.deletePersonFromMatrix(matrix, person);
  }
}
