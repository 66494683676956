
import { Vue, Component, Prop, Model } from 'vue-property-decorator';

export type Colors = Record<string, string[]>;

export function generateColors() {
  const baseColors = [
    'red',
    'pink',
    'purple',
    'deep-purple',
    'indigo',
    'blue',
    'light-blue',
    'cyan',
    'teal',
    'green',
    'light-green',
    'lime',
    'yellow',
    'amber',
    'orange',
    'deep-orange',
    'brown',
    'blue-grey',
    'grey',
  ];
  const shades = [
    'lighten-5',
    'lighten-4',
    'lighten-3',
    'lighten-2',
    'lighten-1',
    'base',
    'darken-1',
    'darken-2',
    'darken-3',
    'darken-4',
  ].reverse();

  const colors = {} as Colors;

  baseColors.forEach((baseColor) => {
    colors[baseColor] = [];
    shades.forEach((shade) => {
      shade === 'base' ? colors[baseColor].push(baseColor) : colors[baseColor].push(`${baseColor} ${shade}`);
    });
  });
  colors.shades = ['white', 'black', 'transparent'];

  return colors;
}

const defaultColors = generateColors();

@Component({ components: {} })
export default class ColorPicker extends Vue {
  // @PropSync('value', { type: String, required: true }) color!: string;
  @Model('select', { default: '' }) color!: string;

  @Prop({ default: () => defaultColors }) colors!: Colors;

  showDialog = false;

  selectColor(color: string) {
    // this.color = color;
    this.$emit('select', color);
    this.showDialog = false;
  }
}
