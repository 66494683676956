import { db, firestore } from '@/firebase';
import {
  SkillMatrix,
  skillMatrixStatuses,
  skillMatrixTypes,
  RatingScales,
  Skill,
  Skills,
  SkillLevel,
  SkillRequirements,
  TeamMember,
  User,
  LearningTargets,
} from './models';
import { deepCopy, downloadJson, getId } from '@/utils';
import Vue from 'vue';

export const proficiencyRatingScales: RatingScales = {
  SKILL_LEVELS: {
    name: 'Skill Levels',
    description: '',
    icon: 'mdi-account-star',
    levels: [
      { id: '0', name: 'No Skill', description: 'I have no experience with this skill', color: 'red' },
      { id: '1', name: 'Basic', description: 'I know some basics but require support for this skill', color: 'yellow' },
      {
        id: '2',
        name: 'Intermediate',
        description: 'I have enough knowledge and can work independently with this skill',
        color: 'light-green',
      },
      {
        id: '3',
        name: 'Advanced',
        description: 'I have deep knowledge and can mentor others with this skill',
        color: 'green darken-2',
      },
    ],
  },
  AWARENESS_LEVELS: {
    name: 'Awareness Levels',
    description: '',
    icon: 'mdi-account-star',
    levels: [
      { id: '0', name: 'Unaware', description: 'I am unaware of this skill', color: 'red' },
      {
        id: '1',
        name: 'Awareness',
        description: 'I am aware of this skill but have no hands-on experience',
        color: 'orange',
      },
      {
        id: '2',
        name: 'Novice',
        description: 'I have some minimal hands-on experience but require support for this skill',
        color: 'yellow',
      },
      {
        id: '3',
        name: 'Professional',
        description: 'I have sufficient hands-on experience and can work independently with this skill',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'Expert',
        description: 'I have extensive experience and can mentor others with this skill',
        color: 'green darken-2',
      },
    ],
  },
  DREYFUS_MODEL: {
    name: 'Dreyfus Model',
    description: '',
    icon: 'mdi-account-star',
    levels: [
      { id: '0', name: 'Novice', description: 'I have no or very little experience in the area', color: 'red' },
      {
        id: '1',
        name: 'Advanced Beginner',
        description: 'I have some experience in the area but require guidance',
        color: 'orange',
      },
      {
        id: '2',
        name: 'Competent',
        description: 'I have moderate experience in the area, and can act based on guidelines and knowledge',
        color: 'yellow',
      },
      {
        id: '3',
        name: 'Proficient',
        description: 'I have significant experience in the area, and can act based on knowledge',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'Expert',
        description: 'I have vast experience in the area, and can act based on knowledge and intuition',
        color: 'green darken-2',
      },
    ],
  },
  THREE_LEVELS: {
    name: '3-Level Scale',
    description: '',
    icon: 'mdi-account-star',
    levels: [
      { id: '0', name: 'Beginner', description: 'I am starting to learn this skill', color: 'red' },
      {
        id: '1',
        name: 'Specialist',
        description: 'I can use autonomously this skill',
        color: 'yellow',
      },
      {
        id: '2',
        name: 'Expert',
        description: 'I can guide others in this skill',
        color: 'green darken-2',
      },
    ],
  },
  FIVE_LEVELS: {
    name: '5-Level Scale',
    description: '',
    icon: 'mdi-account-star',
    levels: [
      { id: '0', name: 'Beginner', description: 'I have basic understanding of this skill', color: 'red' },
      { id: '1', name: 'Novice', description: 'I am able to use this skill with some guidance', color: 'orange' },
      { id: '2', name: 'Competent', description: 'I can practice this skill independently', color: 'yellow' },
      {
        id: '3',
        name: 'Proficient',
        description: 'I can apply autonomously this skill in complex situations',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'Expert',
        description: 'I have deep knowledge and mastery in this skill',
        color: 'green darken-2',
      },
    ],
  },
};
export const defaultProficiencyRatingScale = proficiencyRatingScales.SKILL_LEVELS;

export const importanceRatingScales: RatingScales = {
  DEFAULT: {
    name: 'Importance Levels',
    description: 'Rating scale for assessing skill importance',
    icon: 'mdi-account-alert',
    levels: [
      {
        id: '0',
        name: 'Not Important',
        description: 'This skill has little to no current or foreseeable impact',
        color: 'red',
      },
      {
        id: '1',
        name: 'Minimally Important',
        description: 'This skill has minor impact to the role or team objectives',
        color: 'orange',
      },
      {
        id: '2',
        name: 'Moderately important',
        description: 'This skill is valuable and can contribute to the role or team objectives',
        color: 'yellow',
      },
      {
        id: '3',
        name: 'Important',
        description: 'This skill is very important and regularly needed for the role and team objectives',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'Critical',
        description: 'This skill is essential for the role and team objectives',
        color: 'green darken-2',
      },
    ],
  },
};
export const defaultImportanceRatingScale = importanceRatingScales.DEFAULT;

export const interestRatingScales: RatingScales = {
  DEFAULT: {
    name: 'Interest Levels',
    description: 'Rating scale for assessing upskilling interest',
    icon: 'mdi-account-arrow-up',
    levels: [
      { id: '0', name: 'No Interest', description: 'I have no interest in developing this skill', color: 'red' },
      {
        id: '1',
        name: 'Low Interest',
        description: 'I have little interest in developing this skill',
        color: 'orange',
      },
      { id: '2', name: 'Neutral', description: 'I am neutral towards developing this skill', color: 'yellow' },
      {
        id: '3',
        name: 'Moderate Interest',
        description: 'I am interested in developing this skill',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'High Interest',
        description: 'I am very interested in developing this skill',
        color: 'green darken-2',
      },
    ],
  },
};
export const defaultInterestRatingScales = interestRatingScales.DEFAULT;

export const skillCategories = ['', 'Core', 'Functional', 'Technical', 'Analytical', 'Leadership', 'Social'];

export const reviewTypes = ['Self Assessment', 'Peer Review', 'Manager Feedback'];

export const skillsService = {
  adminRef: db.collection('admin'),
  configRef: db.collection('admin').doc('skills'),
  skillsRef: db.collection('skills'),

  proficiencyRatingScales,
  defaultProficiencyRatingScale,
  importanceRatingScales,
  defaultImportanceRatingScale,
  interestRatingScales,
  defaultInterestRatingScales,

  // Admin
  updateAdmin(docId: string, data: firestore.UpdateData) {
    this.adminRef.doc(docId).update(data);
  },
  updateSkills(docId: string, data: firestore.UpdateData) {
    this.skillsRef.doc(docId).update(data);
  },

  // Firestore
  getSkillMatrix(id: string) {
    return this.skillsRef.doc(id);
  },
  getSkillMatrices(userId: string) {
    return this.skillsRef.where('owner', '==', userId);
  },
  getSkillMatricesByStatus(userId: string, status: string = 'Draft') {
    return this.skillsRef.where('owner', '==', userId).where('status.name', '==', status);
  },

  createSkillMatrix(matrix: SkillMatrix) {
    matrix.createdOn = Date.now();
    // this.skillsRef.doc(matrix.id).update(matrix);
    this.skillsRef.doc(matrix.id).set(matrix, { merge: true });
  },
  updateSkillMatrix(matrix: SkillMatrix | firestore.UpdateData) {
    matrix.updatedOn = Date.now();
    this.updateSkillMatrixData(matrix.id, matrix);
    // this.skillsRef.doc(matrix.id).update(matrix);
  },
  updateSkillMatrixData(matrixId: string, matrixData: firestore.UpdateData) {
    // console.log('updateSkillMatrixData', matrixId, matrixData);
    this.skillsRef.doc(matrixId).update(matrixData);
  },
  deleteSkillMatrix(matrix: SkillMatrix) {
    this.skillsRef.doc(matrix.id).delete();
  },
  saveSkillMatrix(matrix: SkillMatrix) {
    this.createSkillMatrix(matrix);
  },
  setSkillMatrixStatus(matrix: SkillMatrix, status: string) {
    let matrixStatus = skillMatrixStatuses.DRAFT;
    if (status === skillMatrixStatuses.ACTIVE.name) {
      matrixStatus = skillMatrixStatuses.ACTIVE;
    } else if (status === skillMatrixStatuses.CLOSED.name) {
      matrixStatus = skillMatrixStatuses.CLOSED;
    }
    this.skillsRef.doc(matrix.id).update({ status: matrixStatus });
  },
  exportSkillMatrix(matrix: SkillMatrix) {
    downloadJson(matrix, 'skills-' + matrix.id);
  },
  importSkillMatrix(file: File, userId: string): Promise<SkillMatrix> {
    const reader = new FileReader();
    let content: string | ArrayBuffer | null = null;
    let matrix: SkillMatrix | null = null;
    return new Promise((resolve, reject) => {
      reader.readAsText(file, 'UTF-8');
      reader.onload = (event) => {
        if (event && event.target && event.target.result) {
          content = event.target.result;
          try {
            matrix = JSON.parse(content.toString());
            if (matrix) {
              matrix.id = getId(); // Remap id to avoid conflicts!
              matrix.title = matrix.title + ' (Import)';
              matrix.owner = userId;
              matrix.createdOn = Date.now();
              matrix.updatedOn = null;
              resolve(matrix);
            } else {
              reject('Skill matrix content missing');
            }
          } catch (error) {
            reject('Skill matrix content malformed');
          }
        }
      };
      reader.onerror = () => {
        reject('Skill matrix import failed');
      };
    });
  },
  async cloneSkillMatrix(matrix: SkillMatrix, user: User) {
    const newMatrix: SkillMatrix = deepCopy(matrix);
    newMatrix.id = getId();
    newMatrix.status = skillMatrixStatuses.DRAFT;
    newMatrix.title = matrix.title + ' (Copy)';
    newMatrix.people = {};
    Object.entries(newMatrix.skills).forEach(([skillId, skill]) => {
      skill.levels = {};
      skill.learningTargets = {};
      // skill.requirements = Array(Object.keys(newMatrix.config.proficiencyScale.levels).length).fill(0);
    });
    newMatrix.config.filterPersonId = null;
    newMatrix.config.filterGaps = false;
    newMatrix.config.filterUpskill = false;
    newMatrix.config.filterTargets = false;
    newMatrix.owner = user.id;
    newMatrix.createdOn = Date.now();
    newMatrix.updatedOn = null;
    await this.createSkillMatrix(newMatrix);
    return newMatrix;
  },
  saveSkills(matrix: SkillMatrix, skills: Skills) {
    this.skillsRef.doc(matrix.id).update({ ['skills']: skills });
  },
  saveSkill(matrix: SkillMatrix, skill: Skill) {
    this.skillsRef.doc(matrix.id).update({ [`skills.${skill.id}`]: skill });
  },
  deleteSkill(matrix: SkillMatrix, skill: Skill) {
    this.skillsRef.doc(matrix.id).update({ [`skills.${skill.id}`]: firestore.FieldValue.delete() });
  },
  updateSkillRequirements(matrix: SkillMatrix, skill: Skill, skillRequirements: SkillRequirements) {
    this.updateSkillMatrixData(matrix.id, { [`skills.${skill.id}.requirements`]: skillRequirements });
  },
  updatePersonSkillLevels(matrix: SkillMatrix, skill: Skill, person: TeamMember, skillLevels: SkillLevel) {
    this.updateSkillMatrixData(matrix.id, { [`skills.${skill.id}.levels.${person.id}`]: skillLevels });
  },
  updatePersonLearningTargets(matrix: SkillMatrix, skill: Skill, person: TeamMember, learningTargets: LearningTargets) {
    this.updateSkillMatrixData(matrix.id, { [`skills.${skill.id}.learningTargets.${person.id}`]: learningTargets });
  },
  updatePerson(matrixId: string, person: TeamMember) {
    this.skillsRef.doc(matrixId).update({ [`people.${person.id}`]: person });
  },
  deletePerson(matrixId: string, person: TeamMember) {
    this.skillsRef.doc(matrixId).update({ [`people.${person.id}`]: firestore.FieldValue.delete() });
  },

  addPersonToMatrix(matrixId: string, person: TeamMember) {
    this.updatePerson(matrixId, person);
  },
  deletePersonFromMatrix(matrix: SkillMatrix, person: TeamMember) {
    // this.skillsRef.doc(matrix.id).update({ [`people.${person.id}`]: firestore.FieldValue.delete() });
    // delete matrix.people[person.id];
    Vue.delete(matrix.people, person.id);
    const skills = Object.values(matrix.skills);
    for (const skill of skills) {
      if (skill.levels[person.id]) {
        delete skill.levels[person.id];
      }
      if (skill.learningTargets && skill.learningTargets[person.id]) {
        delete skill.learningTargets[person.id];
      }
    }
    this.deletePerson(matrix.id, person);
    this.saveSkills(matrix, matrix.skills);
  },
  // fbAddSkillToMatrix(matrix: SkillMatrix, skill: Skill) {
  //   this.skillsRef.doc(matrix.id).update({ skills: firestore.FieldValue.arrayUnion(skill) });
  // },
  // fbDeleteSkillFromMatrix(matrix: SkillMatrix, skill: Skill) {
  //   this.skillsRef.doc(matrix.id).update({ skills: firestore.FieldValue.arrayRemove(skill) });
  // },

  // Actions
  getNewSkillMatrix(userId: string): SkillMatrix {
    return {
      id: getId(),
      title: 'Skill Matrix',
      description: '',
      background: '',
      type: skillMatrixTypes.PRODUCT,
      status: skillMatrixStatuses.DRAFT,
      // date: Date.now(),
      // skills: {},
      skills: {},
      people: {},
      // requirements: {},
      // currentSkillLevels: {},
      // targetSkillLevels: {},
      // assessments: [],
      config: {
        skillCategories: [],
        proficiencyScale: defaultProficiencyRatingScale,
        // importanceScale: defaultImportanceRatingScale,
        // interestScale: defaultInterestRatingScales,
      },
      owner: userId || '',
    };
  },

  // New methods
  getNewSkill(matrix: SkillMatrix, name = 'New skill'): Skill {
    return {
      id: getId(),
      name,
      description: '',
      category: '',
      requirements: Array(Object.keys(matrix.config.proficiencyScale.levels).length).fill(0),
      levels: {},
    };
  },

  addSkillToMatrix(matrix: SkillMatrix, skill?: Skill) {
    const newSkill = skill || this.getNewSkill(matrix);
    // newSkill.createdOn = Date.now();
    newSkill.order = Object.keys(matrix.skills).length;
    // matrix.skills[newSkill.id] = newSkill;
    Vue.set(matrix.skills, newSkill.id, newSkill);
  },

  deleteSkillFromMatrix(matrix: SkillMatrix, skill: Skill) {
    // delete matrix.skills[skill.id];
    Vue.delete(matrix.skills, skill.id);
  },

  // Getters
  getSkillMatrixMembers(matrix: SkillMatrix) {
    return Object.values(matrix.people).sort((a, b) => a.username!.localeCompare(b.username!)); // Alphabetically sorted
  },
  getSkillRequirements(matrix: SkillMatrix, skill: Skill) {
    return skill.requirements;
  },
  getSkillCoverage(matrix: SkillMatrix, skill: Skill) {
    const requiredPeople: number[] = new Array(matrix.config.proficiencyScale.levels.length).fill(0);
    const availablePeople: number[] = new Array(matrix.config.proficiencyScale.levels.length).fill(0);
    const requiredLevels = this.getSkillRequirements(matrix, skill);
    const currentLevels = skill.levels;
    for (const level in matrix.config.proficiencyScale.levels) {
      requiredPeople[level] = requiredLevels[level];
      availablePeople[level] = Object.values(currentLevels).filter((l) => l.currentLevel === level).length; // People with required skill level or higher!
    }
    const coverage = this.calculateSkillCoverage(requiredPeople, availablePeople);
    return coverage;
  },
  getSkillCoverageColor(matrix: SkillMatrix, skill: Skill) {
    const value = this.getSkillCoverage(matrix, skill);
    return this.getProgressColor(value);
  },
  getProgressColor(value?: number) {
    if (value === undefined) return 'grey';
    if (value <= 25) return 'red';
    if (value <= 50) return 'orange';
    if (value <= 75) return 'yellow';
    return 'green';
  },
  getSkillGaps(matrix: SkillMatrix, skill: Skill) {
    const requiredPeople: number[] = new Array(matrix.config.proficiencyScale.levels.length).fill(0);
    const availablePeople: number[] = new Array(matrix.config.proficiencyScale.levels.length).fill(0);
    const requiredLevels = this.getSkillRequirements(matrix, skill);
    const currentLevels = skill.levels;
    for (const level in matrix.config.proficiencyScale.levels) {
      requiredPeople[level] = requiredLevels[level];
      availablePeople[level] = Object.values(currentLevels).filter((l) => l.currentLevel === level).length; // People with required skill level or higher!
    }
    const gaps = this.calculateSkillGap(requiredPeople, availablePeople);
    return gaps;
  },
  getSkillLevelColor(matrix: SkillMatrix, skillLevel: string | null) {
    const level = Number(skillLevel || 0);
    const color = !skillLevel ? 'grey' : matrix.config.proficiencyScale.levels[level].color;
    return color;
  },
  calculateSkillGap(required: number[], available: number[]): number[] {
    const levelsCount = required.length;
    const skillGap = new Array(levelsCount).fill(0);
    const remaining = available.slice(); // Copy of available array to track remaining people
    // Iterate from highest skill level to lowest
    for (let level = levelsCount - 1; level >= 0; level--) {
      // If available people are enough for the required number
      if (remaining[level] >= required[level]) {
        remaining[level] -= required[level];
      } else {
        // If available people are less than required, calculate the gap
        skillGap[level] = required[level] - remaining[level];
        remaining[level] = 0;
      }
      // Carry over any remaining people to the next lower skill level
      if (level > 0) {
        remaining[level - 1] += remaining[level];
      }
    }
    return skillGap;
  },
  calculateSkillCoverage(required: number[], available: number[]): number {
    const levelsCount = required.length;
    // Initialize variables to track total required and total fulfilled
    let totalRequired = 0;
    let totalFulfilled = 0;
    const remaining = available.slice(); // Copy of available array to track remaining people
    // Iterate from highest skill level to lowest
    for (let level = levelsCount - 1; level >= 0; level--) {
      totalRequired += required[level];
      if (remaining[level] >= required[level]) {
        // If available people are enough for the required number
        totalFulfilled += required[level];
        remaining[level] -= required[level];
      } else {
        // If available people are less than required, add the available to fulfilled
        totalFulfilled += remaining[level];
        remaining[level] = 0;
      }
      // Carry over any remaining people to the next lower skill level
      if (level > 0) {
        remaining[level - 1] += remaining[level];
      }
    }
    // Calculate the total skill coverage percentage
    const skillCoveragePercentage = totalRequired !== 0 ? Math.round((totalFulfilled / totalRequired) * 100) : 0;
    return skillCoveragePercentage;
  },
  getSkillLevelPeople(matrix: SkillMatrix, skill: Skill) {
    const levels = skill.levels;
    const peopleWithSkillLevel: number[] = [];
    for (const level of Object.values(matrix.config.proficiencyScale.levels)) {
      peopleWithSkillLevel[Number(level.id)] = Object.values(levels).filter((l) => l.currentLevel === level.id).length;
    }
    return peopleWithSkillLevel;
  },
  getPersonCurrentSkillLevel(matrix: SkillMatrix, person: TeamMember, skill: Skill) {
    if (!skill.levels[person.id]) skill.levels[person.id] = { currentLevel: null, targetLevel: null };
    return skill.levels[person.id].currentLevel || null;
  },
  getPersonCurrentSkillLevelColor(matrix: SkillMatrix, person: TeamMember, skill: Skill) {
    const level = this.getPersonCurrentSkillLevel(matrix, person, skill);
    return level ? matrix.config.proficiencyScale.levels[Number(level)].color : 'grey';
  },
  getPersonCurrentSkillLevels(matrix: SkillMatrix, person: TeamMember) {
    const levels: (string | null)[] = [];
    for (const skill of Object.values(matrix.skills)) {
      levels.push(this.getPersonCurrentSkillLevel(matrix, person, skill));
    }
    return levels;
  },
  getPersonTargetSkillLevel(matrix: SkillMatrix, person: TeamMember, skill: Skill) {
    if (!skill.levels[person.id]) skill.levels[person.id] = { currentLevel: null, targetLevel: null };
    return skill.levels[person.id].targetLevel || null;
  },
  getPersonTargetSkillLevelColor(matrix: SkillMatrix, person: TeamMember, skill: Skill) {
    const level = this.getPersonTargetSkillLevel(matrix, person, skill);
    return level ? matrix.config.proficiencyScale.levels[Number(level)].color : 'grey';
  },
  getPersonTargetSkillLevels(matrix: SkillMatrix, person: TeamMember) {
    const levels: (string | null)[] = [];
    for (const skill of Object.values(matrix.skills)) {
      levels.push(this.getPersonTargetSkillLevel(matrix, person, skill));
    }
    return levels;
  },
  getPersonSkillLearningTargets(person: TeamMember, skill: Skill) {
    const learningTargets = skill.learningTargets || {};
    const personLearningTargets = learningTargets[person.id] || [];
    return personLearningTargets;
  },
  getPersonSkillLearningGap(person: TeamMember, skill: Skill) {
    const skillLevels = skill.levels || {};
    const personSkillLevels = skillLevels[person.id] || [];
    return Number(personSkillLevels.targetLevel || 0) - Number(personSkillLevels.currentLevel || 0);
  },
  getSkillUpskilling(matrix: SkillMatrix, skill: Skill) {
    const skillLevels = skill.levels || {};
    const upskilling = new Array(matrix.config.proficiencyScale.levels.length).fill(0);
    for (const personId in skillLevels) {
      const personSkillLevels = skillLevels[personId] || {};
      const isUpskilling = Number(personSkillLevels.targetLevel || 0) > Number(personSkillLevels.currentLevel || 0);
      if (isUpskilling) {
        const level = Number(personSkillLevels.targetLevel || 0);
        upskilling[level]++;
      }
    }
    return upskilling;
  },
  getSkillLearningGaps(skill: Skill) {
    const skillLevels = skill.levels || {};
    const learningGaps: { [personId: string]: number } = {};
    for (const personId in skillLevels) {
      const personSkillLevels = skillLevels[personId] || {};
      learningGaps[personId] = Number(personSkillLevels.targetLevel || 0) - Number(personSkillLevels.currentLevel || 0);
    }
    return learningGaps;
  },
  getSkillLearningTargets(skill: Skill) {
    return skill.learningTargets || {};
  },
  getLearningTargets(matrix: SkillMatrix) {
    // if (!matrix) return [];
    const skills = Object.values(matrix.skills) || [];
    const targets: any = [];
    for (const skill of skills) {
      if (!skill.learningTargets) continue;
      for (const person of Object.values(matrix.people)) {
        for (const target of skill.learningTargets[person.id] || []) {
          const extendedTarget = { skill, person, ...target };
          targets.push(extendedTarget);
        }
      }
    }
    return targets;
  },

  updatePersonCurrentSkillLevel(matrix: SkillMatrix, person: TeamMember, skill: Skill, level: string) {
    skill.levels[person.id].currentLevel = level;
    // console.log('updatePersonCurrentSkillLevel', skill.levels[person.id].currentLevel);
  },
  updatePersonTargetSkillLevel(matrix: SkillMatrix, person: TeamMember, skill: Skill, level: string) {
    skill.levels[person.id].targetLevel = level;
    // console.log('updatePersonTargetSkillLevel', skill.levels[person.id].targetLevel);
  },
};
