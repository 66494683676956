
import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@/utils';
import { SkillMatrices } from '../models';
import { skillsService } from '../services';
import SkillMatrixActions from './SkillMatrixActions.vue';

@Component({ components: { SkillMatrixActions } })
export default class SkillMatricesList extends Vue {
  @Prop({ type: Array as () => SkillMatrices, required: true }) readonly matrices!: SkillMatrices;

  formatDate = formatDate;
  skillsService = skillsService;

  get viewModeGrid(): boolean {
    return this.$store.getters['user/viewModeGrid'];
  }
}
