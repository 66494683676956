
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Skill, SkillMatrix, skillMatrixStatuses, TeamMember, User } from '../models';
import { skillsService } from '../services';
import { deepCopy, formatDate } from '@/utils';
import Participants from '@/components/Participants.vue';
import SkillMatrixSettings from '../components/SkillMatrixSettings.vue';
import ImportSkillsDialog from '../components/dialogs/ImportSkillsDialog.vue';
import UpdateSkillDialog from '../components/dialogs/UpdateSkillDialog.vue';
import UpdateSkillLevelDialog from '../components/dialogs/UpdateSkillLevelDialog.vue';
import SkillMatrixChartDialog from '../components/dialogs/SkillMatrixChartDialog.vue';
import UpdatePersonDialog from '../components/dialogs/UpdatePersonDialog.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    Participants,
    ImportSkillsDialog,
    UpdateSkillDialog,
    UpdateSkillLevelDialog,
    UpdatePersonDialog,
    SkillMatrixChartDialog,
    SkillMatrixSettings,
  },
})
export default class SkillMatrixView extends Vue {
  @Prop({ type: String, required: true }) readonly id!: string;

  matrix: SkillMatrix | null = null;

  newSkill = '';
  currentSkill: Skill | null = null;
  currentPerson: TeamMember | null = null;
  enableAdvancedSettings = false;

  showSkillDialog = false;
  showLevelDialog = false;
  showImportDialog = false;
  showChartDialog = false;
  showPersonDialog = false;

  formatDate = formatDate;
  skillsService = skillsService;
  skillMatrixStatuses = skillMatrixStatuses;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  get isReadOnly(): boolean {
    return this.matrix?.status.name === skillMatrixStatuses.CLOSED.name;
  }
  get isOwner(): boolean {
    return this.matrix?.owner === this.userId;
  }
  get isAdmin(): boolean {
    return this.matrix?.owner === this.userId;
  }
  get permissions() {
    return {
      canUpdateSkillMatrix: this.isOwner || this.isAdmin,
      canUpdateSkill: (this.isOwner || this.isAdmin) && !this.isReadOnly,
      canUpdateOwnSkillLevels: !this.isReadOnly,
      canUpdateOthersSkillLevels: (this.isOwner || this.isAdmin) && !this.isReadOnly,
      canViewOthersSkillLevels: (this.isOwner || this.isAdmin) && !this.isReadOnly,
      canUpdateSettings: (this.isOwner || this.isAdmin) && !this.isReadOnly,
      canUpdatePerson: this.isOwner || this.isAdmin,
    };
  }
  get skills() {
    if (!this.matrix) return [];
    let skills = Object.values(this.matrix.skills);
    if (this.matrix.config.filterGaps) {
      skills = skills.filter((skill) => skillsService.getSkillGaps(this.matrix!, skill).some((gap) => gap > 0));
    }
    if (this.matrix.config.filterUpskill) {
      skills = skills.filter((skill) =>
        Object.values(skillsService.getSkillLearningGaps(skill)).some((gap) => gap > 0)
      );
    }
    if (this.matrix.config.filterTargets) {
      skills = skills.filter((skill) =>
        Object.values(skillsService.getSkillLearningTargets(skill)).some((gap) => gap.length > 0)
      );
    }
    return skills.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  }
  get learningTargets() {
    return this.matrix ? skillsService.getLearningTargets(this.matrix) : [];
  }
  get uniqueCategories() {
    return this.matrix
      ? Object.values(this.matrix.skills)
          .map((skill) => skill.category)
          .filter((category, index, self) => category && self.indexOf(category) === index)
      : [];
  }
  get sortedPeople() {
    return Object.values(this.matrix?.people || {}).sort((a, b) => (a.username || '').localeCompare(b.username || ''));
  }
  get textColorClass() {
    return this.$vuetify.theme.dark ? 'white--text' : this.matrix?.background ? 'white--text' : 'default--text';
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    await this.$bind('matrix', skillsService.getSkillMatrix(id));
    await this.connect();
    this.$bus.$emit('loading-indicator', false);
    if (this.matrix) {
      document.title = this.matrix.title;
      this.$analytics.logEvent('skills-view', { matrix_id: this.id, user_id: this.userId });
    }
  }

  @Watch('matrix')
  onSessionChange(matrix: SkillMatrix) {
    if (matrix) {
      document.title = matrix.title;
      // this.isOwner = matrix.owner === this.userId;
      this.$bus.$emit('loading-indicator', false);
    }
  }

  created() {
    window.onbeforeunload = this.onBeforeUnload;
  }

  mounted() {
    this.$bus.$emit('title-change', 'Skills', '/skills');
    this.$bus.$emit('loading-indicator', true);
  }

  async beforeRouteLeave(to, from, next) {
    await this.disconnect();
    next();
  }

  onBeforeUnload() {
    this.disconnect();
  }

  async connect() {
    if (this.isReadOnly) return;
    let person: TeamMember;
    if (this.matrix?.people[this.userId]) {
      person = this.matrix?.people[this.userId];
      person = { ...person, ...this.user };
      // console.log('Person already exists in matrix', person, this.user);
    } else {
      person = this.user;
    }
    person.lastJoinedOn = Date.now(); // Update last joined date
    await skillsService.addPersonToMatrix(this.id, person);
    // this.$bus.$emit('snackbar-notify', 'Skill matrix user connected', 'success');
  }

  async disconnect() {
    if (this.isReadOnly || !this.matrix) return;
    // await skillsService.deletePersonFromMatrix(this.matrix, this.user); // Do not disconnect because this removes the person skill level inputs!
    // this.$bus.$emit('snackbar-notify', 'Skill matrix user disconnected', 'success');
  }

  async saveSkillMatrix(matrix: SkillMatrix) {
    try {
      // matrix.createdOn = Date.now();
      // matrix.owner = this.userId;
      await skillsService.updateSkillMatrix(matrix);
      this.$bus.$emit('snackbar-notify', 'Skill matrix successfully saved', 'success');
      this.$analytics.logEvent('skills-view', { matrix_id: matrix.id, user_id: this.userId });
      this.$router.push({ name: 'skills-list' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async addSkill(matrix: SkillMatrix) {
    const skill: Skill = skillsService.getNewSkill(matrix, this.newSkill);
    skillsService.addSkillToMatrix(matrix, skill);
    await skillsService.saveSkillMatrix(matrix);
    // await skillsService.addSkillToMatrix(matrix, skill)
    this.newSkill = '';
  }

  openSkillDialog(matrix: SkillMatrix, skill: Skill) {
    if (!this.permissions.canUpdateSkill) return;
    this.currentSkill = deepCopy(skill);
    // if (!matrix.requirements[skill.id]) {
    //   matrix.requirements[skill.id] = new Array(matrix.config.proficiencyScale.levels.length).fill(0);
    // }
    this.showSkillDialog = true;
  }

  openLevelDialog(matrix: SkillMatrix, skill: Skill, person: TeamMember) {
    if (this.isReadOnly) return;
    if (!skill.levels[person.id]) skill.levels[person.id] = { currentLevel: null, targetLevel: null };
    this.currentSkill = deepCopy(skill);
    this.currentPerson = person;
    this.showLevelDialog = true;
  }

  openPersonDialog(matrix: SkillMatrix, person: TeamMember) {
    if (!this.permissions.canUpdatePerson) return;
    this.currentPerson = person;
    this.showPersonDialog = true;
  }

  getPersonName(personId: string) {
    return this.matrix?.people[personId]?.username || 'Unknown';
  }

  selectPerson(person: TeamMember) {
    if (!this.matrix) return;
    // this.matrix.config.filterPersonId = person?.id;
    Vue.set(this.matrix.config, 'filterPersonId', person?.id);
  }

  avatarColor(person: TeamMember) {
    if (person.id === this.userId) return 'primary';
    if (person.active) return 'green';
    return 'grey';
  }

  showPersonSkills(matrix: SkillMatrix, person: TeamMember) {
    if (matrix.owner === person.id && !matrix.config.showOwnerSkills) return false;
    return (
      matrix.config.showEveryoneSkills ||
      person.id === this.userId ||
      this.permissions.canViewOthersSkillLevels ||
      this.isReadOnly
    );
  }

  getPersonSkillsPanelClass(person: TeamMember) {
    if (this.matrix?.config.filterPersonId === person.id) return 'selectedUser';
    if (person.id === this.userId) return 'currentUser';
    // background-color: #99999933 !important;
    return 'default';
  }

  updateMatrixData(name, value) {
    skillsService.updateSkillMatrixData(this.id, { [name]: value });
  }
}
