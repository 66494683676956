
import { Vue, Component, Prop, ModelSync } from 'vue-property-decorator';
import { hexColor } from '@/utils';
import apexchart from 'vue-apexcharts';
import { SkillMatrix } from '../../models';
import { skillsService } from '../../services';

@Component({ components: { apexchart } })
export default class SkillMatrixChartDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  @Prop({ type: Object as () => SkillMatrix, required: true }) readonly matrix!: SkillMatrix;

  get levels() {
    return this.matrix.config.proficiencyScale.levels;
  }

  get skills() {
    return Object.values(this.matrix.skills);
  }

  get skillNames() {
    return this.skills.map((skill) => skill.name);
  }

  get peopleSkillLevelsOptions() {
    return {
      chart: {
        type: 'radar',
      },
      title: {
        text: 'Team Skills Distribution',
      },
      theme: {
        mode: this.$vuetify.theme.dark ? 'dark' : 'light',
      },
      dataLabels: {
        enabled: false,
        background: {
          enabled: true,
          borderRadius: 2,
        },
      },
      fill: {
        opacity: 0.5,
      },
      plotOptions: {
        radar: {
          polygons: {
            fill: {
              colors: this.levels.map((level) => hexColor(level.color)).reverse(),
            },
          },
        },
      },
      xaxis: {
        categories: this.skillNames,
      },
      yaxis: {
        min: 0,
        max: this.levels.length - 1,
        stepSize: 1,
        labels: {
          formatter: (value: number) => this.levels[value]?.name,
        },
      },
    };
  }

  get peopleSkillLevels() {
    return Object.values(this.matrix.people).map((person) => ({
      name: person.displayName || person.username,
      data: skillsService.getPersonCurrentSkillLevels(this.matrix, person),
    }));
  }

  get skillLevelPeopleOptions() {
    return {
      chart: {
        type: 'bar',
        stacked: true,
      },
      title: {
        text: 'People per Skill Level',
      },
      theme: {
        mode: this.$vuetify.theme.dark ? 'dark' : 'light',
      },
      bar: {
        horizontal: true,
      },
      colors: this.levels.map((level) => hexColor(level.color)),
      xaxis: {
        categories: this.skillNames,
      },
      yaxis: {
        min: 0,
        max: this.levels.length - 1,
        stepSize: 1,
        labels: {
          formatter: (value: number) => (value == 1 ? `${value} person` : `${value} people`),
        },
      },
    };
  }

  get skillLevelPeople() {
    const skillCounts: any = [];
    for (const level of this.levels) {
      const levelCounts = new Array(this.skills.length).fill(0);
      for (const [index, skill] of this.skills.entries()) {
        levelCounts[index] = Object.values(this.matrix.people).filter(
          (person) => skillsService.getPersonCurrentSkillLevel(this.matrix, person, skill) === level.id
        ).length;
      }
      skillCounts.push({ name: level.name, data: levelCounts });
    }
    return skillCounts;
  }
}
