
import { Vue, Component } from 'vue-property-decorator';
import { SkillMatrix } from '../models';
import { skillsService } from '../services';
import SkillMatrixBuilder from '../components/SkillMatrixBuilder.vue';

@Component({ components: { SkillMatrixBuilder } })
export default class SkillMatrixCreate extends Vue {
  matrix: SkillMatrix = skillsService.getNewSkillMatrix(this.userId);

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  mounted() {
    this.$bus.$emit('title-change', 'Skills', '/skills');
    document.title = 'AgileMate New Skill Matrix';
    //this.$bus.$emit('loading-indicator', true);
  }

  async saveSkillMatrix(matrix: SkillMatrix) {
    try {
      // matrix.createdOn = Date.now();
      // matrix.owner = this.userId;
      await skillsService.createSkillMatrix(matrix);
      this.$bus.$emit('snackbar-notify', 'Skill matrix successfully saved', 'success');
      this.$analytics.logEvent('skills-create', { matrix_id: matrix.id, user_id: this.userId });
      this.$router.push({ name: 'skills-list' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
