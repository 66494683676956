
import { Vue, Component, ModelSync, Prop } from 'vue-property-decorator';
import { Skill, SkillMatrix } from '../../models';
import { skillCategories, skillsService } from '../../services';

@Component({ components: {} })
export default class UpdateSkillDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  @Prop({ required: true }) readonly matrix!: SkillMatrix;
  @Prop({ required: true }) readonly skill!: Skill;

  skillsService = skillsService;
  skillCategories = skillCategories;
  isFormValid = false;

  saveSkill(matrix: SkillMatrix, skill: Skill) {
    this.show = false;
    skillsService.saveSkill(matrix, skill);
  }

  deleteSkill(matrix: SkillMatrix, skill: Skill) {
    this.show = false;
    skillsService.deleteSkill(matrix, skill);
  }
}
