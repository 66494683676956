
import { Vue, Component, ModelSync, Prop, Watch } from 'vue-property-decorator';
import { LearningTarget, LearningTargets, Skill, SkillMatrix, TeamMember } from '../../models';
import { skillCategories, skillsService } from '../../services';
import { formatDate, getId } from '@/utils';
import PersonSkillLevelChartDialog from './PersonSkillsChartDialog.vue';

@Component({ components: { PersonSkillLevelChartDialog } })
export default class UpdateSkillLevelDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  @Prop({ required: true }) readonly matrix!: SkillMatrix;
  @Prop({ required: true }) readonly skill!: Skill;
  @Prop({ required: true }) readonly person!: TeamMember;

  skillsService = skillsService;
  skillCategories = skillCategories;
  showChart = false;
  isFormValid = false;

  learningCategories = ['Self-Learning', 'Mentoring', 'Training', 'Certification'];
  learningTarget: LearningTarget = { id: getId(), name: '', category: '' };
  learningTargets: LearningTargets = [];

  formatDate = formatDate;

  @Watch('skill', { immediate: true })
  onSkillChange() {
    this.learningTarget = { id: getId(), name: '', category: '' };
    this.learningTargets =
      this.skill.learningTargets && this.skill.learningTargets[this.person.id]
        ? this.skill.learningTargets[this.person.id]
        : [];
    // console.log('onSkillChange', this.learningTargets);
  }

  get lastUpdateDate() {
    return this.skill.levels[this.person.id].date
      ? formatDate(this.skill.levels[this.person.id].date, 'DD MMMM YYYY')
      : 'Never';
  }

  saveAssessment(matrix: SkillMatrix, skill: Skill) {
    this.show = false;
    const skillLevels = skill.levels[this.person.id];
    const learningTargets = skill.learningTargets ? skill.learningTargets[this.person.id] || [] : [];
    skillLevels.date = Date.now();
    skillsService.updatePersonSkillLevels(matrix, skill, this.person, skillLevels);
    skillsService.updatePersonLearningTargets(matrix, skill, this.person, learningTargets);
  }

  addLearningTarget() {
    if (!this.skill.learningTargets) this.skill.learningTargets = {};
    if (!this.skill.learningTargets[this.person.id]) this.skill.learningTargets[this.person.id] = [];
    this.learningTargets.push(this.learningTarget);
    this.learningTarget = { id: getId(), name: '', category: '' };
    this.skill.learningTargets[this.person.id] = this.learningTargets;
  }

  deleteLearningTarget(idx: number) {
    this.learningTargets.splice(idx, 1);
    if (this.skill.learningTargets && this.skill.learningTargets[this.person.id]) {
      this.skill.learningTargets[this.person.id] = this.learningTargets;
    }
  }
}
