
import { Vue, Component, PropSync } from 'vue-property-decorator';
import { SkillMatrixConfig } from '../models';

@Component
export default class SkillMatrixSettings extends Vue {
  @PropSync('value', { type: Object as () => SkillMatrixConfig, required: true }) config!: SkillMatrixConfig;

  toggleSetting(name: string, value: boolean) {
    // console.log('toggleSetting', name, value);
    this.$emit('change', name, value ? true : false);
  }
}
