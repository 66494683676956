
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { SkillMatrix, User } from '../models';
import { skillsService } from '../services';
import SkillMatrixBuilder from '../components/SkillMatrixBuilder.vue';

@Component({ components: { SkillMatrixBuilder } })
export default class SkillMatrixEdit extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  matrix: SkillMatrix | null = null;
  isOwner: boolean | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  @Watch('id', { immediate: true })
  onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('matrix', skillsService.getSkillMatrix(id));
  }

  @Watch('matrix')
  onSessionChange(matrix: SkillMatrix) {
    if (matrix) {
      document.title = matrix.title;
      this.isOwner = matrix.owner === this.userId;
      this.$bus.$emit('loading-indicator', false);
    }
  }

  created() {
    // Event listeners
    this.$bus.$off('skillmatrix-delete').$on('skillmatrix-delete', this.deleteSkillMatrix);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Skills', '/skills');
    this.$bus.$emit('loading-indicator', true);
  }

  async saveSkillMatrix(matrix: SkillMatrix) {
    try {
      // matrix.createdOn = Date.now();
      // matrix.owner = this.userId;
      await skillsService.updateSkillMatrix(matrix);
      this.$bus.$emit('snackbar-notify', 'Skill matrix successfully saved', 'success');
      this.$analytics.logEvent('skills-edit', { matrix_id: matrix.id, user_id: this.userId });
      this.$router.push({ name: 'skills-list' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }

  async deleteSkillMatrix(matrix: SkillMatrix) {
    try {
      await skillsService.deleteSkillMatrix(matrix);
      this.$bus.$emit('snackbar-notify', 'Skill matrix successfully deleted', 'success');
      this.$analytics.logEvent('skills-delete', { matrix_id: matrix.id, user_id: this.userId });
      this.$router.push({ name: 'skills-list' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
