
import { Vue, Component, Prop } from 'vue-property-decorator';
import { SkillMatrix } from '../models';

@Component
export default class SkillMatrixActions extends Vue {
  @Prop({ type: Object as () => SkillMatrix, required: true }) readonly value!: SkillMatrix;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }

  get isOwner(): boolean {
    return this.value.owner === this.userId;
  }
}
