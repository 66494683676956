import type { User, Participant as TeamMember } from '@/models';

export type TeamMembers = { [userId: string]: TeamMember };

export { User, TeamMember };

export type SkillRequirements = number[];
// export type SkillRequirements = { [level: string]: number[] };

export interface SkillLevel {
  // memberId: string;
  currentLevel: string | null;
  targetLevel: string | null;
  interestLevel?: string | null;
  date?: number;
}
export type SkillLevels = { [personId: string]: SkillLevel };
// export type PersonSkillLevels = { [level: string]: number }; // number
// export type SkillLevels = { [personId: string]: PersonSkillLevels };

export interface LearningTarget {
  id: string;
  name: string;
  description?: string;
  category?: string;
  startDate?: string;
  endDate?: string;
  progress?: number;
}
export type LearningTargets = LearningTarget[];
export type PersonalLearningTargets = { [personId: string]: LearningTargets };

export interface Skill {
  id: string;
  name: string;
  description: string;
  category: string;
  requirements: SkillRequirements;
  levels: SkillLevels;
  learningTargets?: PersonalLearningTargets;
  order?: number;
  // currentSkillLevels: SkillLevels;
  // targetSkillLevels: SkillLevels;
}
// export type Skills = Skill[];
export type Skills = { [skillId: string]: Skill };

export const skillMatrixStatuses = {
  DRAFT: { name: 'Draft', color: 'warning' },
  ACTIVE: { name: 'Active', color: 'success' },
  CLOSED: { name: 'Closed', color: 'error' },
};
export type SkillMatrixStatus = typeof skillMatrixStatuses.DRAFT;

export const skillMatrixTypes = {
  TEAM: { name: 'Team matrix' },
  ROLE: { name: 'Role matrix' },
  PRODUCT: { name: 'Product matrix' },
  DEPARTMENT: { name: 'Department matrix' },
  ORGANIZATION: { name: 'Organization matrix' },
};
export type SkillMatrixType = typeof skillMatrixTypes.PRODUCT;

export const skillCategories = {
  NONE: { name: '' },
  CORE: { name: 'Core' },
  FUNC: { name: 'Functional' },
  TECH: { name: 'Technical' },
  LEAD: { name: 'Leadership' },
  CERT: { name: 'Certification' },
};
export type SkillCategory = typeof skillCategories.NONE;

export const reviewTypes = {
  SELF: { name: 'Self Assessment' },
  PEER: { name: 'Peer Review' },
  MANAGER: { name: 'Manager Feedback' },
};
export type ReviewType = typeof reviewTypes.SELF;

export interface Rating {
  id: string;
  name: string;
  description: string;
  color: string;
}
export type Ratings = Rating[];

export interface RatingScale {
  name: string;
  description: string;
  icon: string;
  levels: Ratings;
}
export type RatingScales = { [scaleId: string]: RatingScale };

// export interface SkillAssessment {
//   id: string;
//   type: ReviewType;
//   date: number;
//   reviewer: TeamMember;
//   reviewee: TeamMember;
//   levels: SkillLevels;
// }

export interface SkillMatrixConfig {
  skillCategories: string[];
  proficiencyScale: RatingScale;
  importanceScale?: RatingScale;
  interestScale?: RatingScale;
  showOwnerSkills?: boolean | null;
  showEveryoneSkills?: boolean | null;
  filterPersonId?: string | null;
  filterSkillId?: string | null;
  filterGaps?: boolean | null;
  filterUpskill?: boolean | null;
  filterTargets?: boolean | null;
}

export interface SkillMatrix {
  id: string;
  title: string;
  description: string;
  background: string;
  type: SkillMatrixType;
  status: SkillMatrixStatus;
  // date: number;
  // owner: User;
  skills: Skills;
  people: TeamMembers;
  // requirements: SkillRequirements;
  // currentSkillLevels: SkillLevels;
  // targetSkillLevels: SkillLevels;
  // assessments: SkillAssessment[];
  config: SkillMatrixConfig;
  owner: string;
  createdOn?: number;
  updatedOn?: number | null;
}
export type SkillMatrices = SkillMatrix[];
