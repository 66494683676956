
import { Vue, Component, ModelSync, Prop } from 'vue-property-decorator';
import { SkillMatrix } from '../../models';
import { skillsService } from '../../services';

@Component({ components: {} })
export default class ImportSkillsDialog extends Vue {
  @ModelSync('value', 'show', { default: false }) show!: Boolean;

  @Prop({ required: true }) readonly matrix!: SkillMatrix;
  @Prop({ default: false }) readonly save!: boolean;

  importSkillsList = '';

  importSkills(matrix: SkillMatrix, skills: string) {
    const skillsArray = skills.split('\n').map((skill) => {
      const [name, category = ''] = skill.split(',').map((s) => s.trim());
      return { name, category };
    });
    skillsArray.forEach(({ name, category }) => {
      if (name) {
        const skill = skillsService.getNewSkill(matrix);
        skill.name = name;
        skill.category = category;
        // matrix.skills[skill.id] = skill;
        skillsService.addSkillToMatrix(matrix, skill);
      }
    });
    this.show = false;
    this.importSkillsList = '';
    if (this.save) skillsService.saveSkillMatrix(matrix);
  }
}
